@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700&display=swap');

.privacy-main {
    background-color: #ffff;
    margin: 0 auto;
    padding: 100px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.privacy-header-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white; 
    z-index: 1000; 
    padding: 40px 0;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); 
}

.privacy-container {
    max-width: 1200px;
    min-height: 100vh;
    margin: 80px auto 0;
    display: flex;
    flex-direction: column; 
    gap: 64px; 
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    position: relative;
}

.privacy-heading {
    width: 100%;
    max-width: 1190px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.privacy-heading h1 {
    font-weight: 600;
    font-size: 48px;
    line-height: 52px;
    color: #313131;
    text-align: center;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.privacy-heading p {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #535353;
    text-align: center;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.privacy-content {
    flex: 1;
    display: flex;
    flex-direction: row;
    max-width: 1190px;
    width: 100%;
    height: auto;
    margin: 0 auto;
    gap: 69px;
    overflow-y: auto;
    align-items: flex-start;
    justify-content: center;
}

.privacy-main-content {
    flex: 3;
    width: 868px;
    min-height: 100vh;
    overflow-y: auto; 
    display: flex;
    flex-direction: column; 
    gap: 24px; 
    padding-bottom: 20px;
}

.privacy-main-content h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 40.32px;
    color:#535353;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.privacy-main-content p {
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: #535353;
    font-family: 'Plus Jakarta Sans', sans-serif;
    text-align: justify;
    width: 500px;
}

.privacy-sidebar {
    flex: 1;
    width: 253px;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
}

.privacy-sidebar ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column; 
    gap: 16px;
}

.privacy-sidebar ul li {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #3F3E3E;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.privacy-sidebar a {
    position: relative;
    text-decoration: none;
    color: #333;
    padding-bottom: 5px;
    transition: color 0.3s ease;
}

.privacy-sidebar a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: #3F3E3E;
    transition: width 0.3s ease-in-out;
}

.privacy-sidebar a:hover::after {
    width: 46px;
}

@media screen and (max-width: 480px) {
    .privacy-main {
        overflow-x: hidden;
        width: 100%;
        padding: 80px 20px 20px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        min-height: 100vh;
    }

    .privacy-container {
        width: 100%;
        max-width: 480px;
        height: auto;
        padding: 20px;
        margin: 60px auto 0;
        gap: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .privacy-container .privacy-heading {
        width: 100%;
        max-width: 480px;
        padding: 0 10px;
    }

    .privacy-container .privacy-heading h1 {
        font-size: 25px;
        text-align: left;
        width: 100%;
    }

    .privacy-container .privacy-heading p {
        font-size: 14px;
        text-align: left;
        width: 100%;
    }

    .privacy-container .privacy-content {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .privacy-container .privacy-content .privacy-sidebar {
        display: none;
    }

    .privacy-container .privacy-content .privacy-main-content {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .privacy-container .privacy-content .privacy-main-content h2 {
        font-size: 20px;
    }

    .privacy-container .privacy-content .privacy-main-content p {
        font-size: 14px;
        text-align: justify;
        width: 100%;
    }
}









    