@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700&display=swap');

main {
    background-color: #ffff;
    margin: 0 auto;
    padding: 100px 100px 0; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.header-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white; 
        z-index: 1000; 
        padding: 10px 0; 
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); 
        padding: 40px 0;
    
}



.container {
    max-width: 1200px;
    min-height: 100vh;
    margin: 0 auto; 
    display: flex;
    flex-direction: column; 
    gap: 64px; 
    justify-content: center;
    overflow: hidden;
    margin-top: 80px;
}

.heading {
    max-width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.heading h1 {
    font-weight: 600;
    font-size: 48px;
    line-height: 52px;
    color: #313131;
    text-align: center;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.heading p {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #535353;
    text-align: center;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.content {
    flex: 1;
   display: flex;
   flex-direction: row;
   max-width: 1190px;
   width: 100%;
   height: auto;
   margin: 0 auto;
   gap: 69px;
   overflow-y: auto;
   align-items: flex-start;
}

.main-content {
    flex: 3;
    width: 868px;
    min-height: 100vh;
    overflow-y: auto; 
    display: flex;
    flex-direction: column; 
    gap: 24px; 
    padding-bottom: 20px;
}

.main-content h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 40.32px;
    color:#535353;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.main-content p {
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: #535353;
    font-family: 'Plus Jakarta Sans', sans-serif;
    text-align: justify;
    width: 500px;
}

.sidebar {
    flex: 1;
    width: 253px;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
}

.sidebar ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column; 
    gap: 16px;
}

.sidebar ul li {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #3F3E3E;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.sidebar a {
    position: relative;
    text-decoration: none;
    color: #333; /* Adjust color as needed */
    padding-bottom: 5px; /* Space for the hover effect */
    transition: color 0.3s ease;
}

.sidebar a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px; /* Thickness of the underline rectangle */
    background-color: #3F3E3E; /* Change to desired color */
    transition: width 0.3s ease-in-out;
}

.sidebar a:hover::after {
    width: 46px;
}



/*mobile view*/

@media screen and (max-width: 480px) {
    main {
        overflow-x: hidden;
        width: 100%;
        padding: 80px 20px 20px; /* Increased top padding to account for header */
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        min-height: 100vh;
    }

    .container {
        width: 100%;
        max-width: 480px;
        height: auto;
        padding: 20px;
        margin: 60px auto 0; /* Added top margin to create space below header */
        gap: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .container .heading {
        width: 100%;
        max-width: 480px;
        padding: 0 10px;
    }

    .container .heading h1 {
        font-size: 25px;
        text-align: left;
        width: 100%;
    }

    .container .heading p {
        font-size: 12px;
        text-align: left;
        width: 100%;
    }

    .container .content {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .container .content .sidebar {
        display: none;
    }

    .container .content .main-content {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        
    }

    .container .content .main-content h2 {
        font-size: 20px;
    }

    .container .content .main-content p {
        font-size: 14px;
        text-align: justify;
        width: 100%;
    }


}






    